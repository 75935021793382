:root {
  font-size: 14px;
}

#root {
  height: 100vh;
  width: 100vw;
  overflow: auto;
}

#root .root {
  min-width: 100%;
  min-height: 100%;
  flex-direction: row;
}

.content {
  flex: auto;
  position: relative;
}
